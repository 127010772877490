import { useCallback } from 'react'

import { isString, isNaN } from 'lodash-es'

import { StyledMuiOtpInput } from './OtpInput.styled'
import { type OtpInputProps } from './OtpInput.types'

const OtpInput = (props: OtpInputProps) => {
  const { isError = false, ...otherProps } = props

  const matchIsNumeric = useCallback((text: string) => {
    const isNumber = typeof text === 'number'

    return (isNumber || (isString(text) && text !== '')) && !isNaN(Number(text))
  }, [])

  return (
    <StyledMuiOtpInput
      isError={isError}
      validateChar={matchIsNumeric}
      {...otherProps}
    />
  )
}

export default OtpInput
