import NextLink from 'next/link'

import { ColorModeTypeEnum } from '@enums'

import { ILinkProps } from './Link.types'
import { StyledTypography } from './Link.styled'

const Link = (props: ILinkProps) => {
  const { href, mode = ColorModeTypeEnum.DARK, children, ...otherProps } = props

  return (
    <NextLink href={href} prefetch={false}>
      <a>
        <StyledTypography mode={mode} {...otherProps}>
          {children}
        </StyledTypography>
      </a>
    </NextLink>
  )
}

export default Link
