import { ColorModeTypeEnum } from '@enums'

import Skeleton from '../Skeleton'

import { StyledMuiTelInput } from './TelInput.styled'
import { TelInputProps } from './TelInput.types'

const MenuProps = {
  sx: {
    '& .MuiPaper-root': {
      '.MuiButtonBase-root': {
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover, &:active': {
            backgroundColor: 'var(--Neutral6)'
          }
        }
      },
      backgroundColor: 'var(--Neutral8)',
      '.MuiTypography-root': {
        color: 'var(--Neutral4)'
      }
    }
  }
}

const TelInput = (props: TelInputProps) => {
  const {
    mode = ColorModeTypeEnum.LIGHT,
    isLoading = false,
    isError = false,
    ...otherProps
  } = props

  if (isLoading) {
    return <Skeleton mode={mode} animation="pulse" width="100%" height={40} />
  }

  return (
    <StyledMuiTelInput
      isError={isError}
      mode={mode}
      MenuProps={MenuProps}
      {...otherProps}
    />
  )
}

export default TelInput
