import { type MutableRefObject } from 'react'

const useTrustpilot = (props: {
  trustBoxRef: MutableRefObject<HTMLDivElement>
}) => {
  const { trustBoxRef } = props

  const loadScript = () => {
    const script = document.getElementById('trustpilot-script')

    if (!script) {
      const script = document.createElement('script')

      script.src =
        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      script.defer = true
      script.id = 'trustpilot-script'
      script.onload = () => {
        if (window.Trustpilot) {
          window.Trustpilot.loadFromElement(trustBoxRef.current, true)
        }
      }

      setTimeout(() => {
        document.body.appendChild(script)
      }, 3000)
    } else {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustBoxRef.current, true)
      }
    }
  }

  return {
    loadScript
  }
}

export default useTrustpilot
