import { IconButton, styled, css } from '@mui/material'

import { ColorModeTypeEnum } from '@enums'

export const StyledIconButton = styled(IconButton)<{
  mode?: ColorModeTypeEnum
}>`
  padding: 0;
  cursor: pointer;
  border-radius: 16px;

  ${({ mode = ColorModeTypeEnum.LIGHT }) => {
    if (mode === ColorModeTypeEnum.LIGHT) {
      return css`
        color: var(--Neutral10);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: var(--Neutral2);
          }
        }

        &:active {
          background-color: var(--Neutral4);
        }
      `
    }

    return css`
      color: var(--Neutral1);

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--Neutral6);
        }
      }

      &:active {
        background-color: var(--Neutral9);
      }
    `
  }}
`
