import { ColorModeTypeEnum } from '@enums'

import Skeleton from '../Skeleton'

import { StyledTextField } from './TextField.styled'
import { ITextFieldProps } from './TextField.types'

const TextField = (props: ITextFieldProps) => {
  const {
    mode = ColorModeTypeEnum.LIGHT,
    isLoading = false,
    isError = false,
    isSuccess = false,
    isDatePickerTextField = false,
    isDateSelected = true,
    ...otherProps
  } = props

  if (isLoading) {
    return <Skeleton mode={mode} animation="pulse" width="100%" height={40} />
  }

  return (
    <StyledTextField
      mode={mode}
      variant="outlined"
      isDatePickerTextField={isDatePickerTextField}
      isError={isError}
      isSuccess={isSuccess}
      isDateSelected={isDateSelected}
      {...otherProps}
    />
  )
}

export default TextField
