import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useCallback } from 'react'

import { Carousel } from 'react-responsive-carousel'

import { Stack } from '@mui/material'

import { Title } from '@components/ui/atoms'

import { replaceImagePathTemplate } from '@helpers/competitions/replace-image-path-template'

import { type IAllTimeWinnersProps } from './AllTimeWinners.types'

const AllTimeWinners = (props: IAllTimeWinnersProps) => {
  const { values = [] } = props

  const router = useRouter()

  const renderCarouselPics = useCallback(() => {
    return values.map((value, index) => {
      const { winnerName, prizeName, drawTime, winnerProfileImage } = value

      return (
        <Link
          key={`all-time-winner-${index}`}
          href="/winners-podium"
          prefetch={false}
        >
          <a className="ptr">
            <Image
              loading="lazy"
              alt={`${winnerName}-${prizeName}`}
              src={replaceImagePathTemplate(
                winnerProfileImage,
                'medium',
                'webp',
                2
              )}
              width={600}
              height={350}
            />

            {prizeName && (
              <h4 className="px-4 mt-4 mb-2">
                <span>{prizeName}</span>
              </h4>
            )}

            {winnerName && <p>{winnerName}</p>}

            {drawTime && <p>{drawTime}</p>}
          </a>
        </Link>
      )
    })
  }, [values])

  if (values.length === 0) {
    return null
  }

  return (
    <Stack direction="column" gap="16px" width="100%" component="section">
      <Title variant="h2">Top 3 All Time Winners</Title>

      <div className="row justify-content-md-center all-time-winners">
        <div className="col col-md-7 ptr">
          <Carousel
            autoPlay={true}
            showArrows={true}
            emulateTouch={true}
            infiniteLoop={true}
            interval={5000}
            showThumbs={false}
            stopOnHover={true}
            showIndicators={true}
            showStatus={false}
            onClickItem={async () => {
              await router.push('/winners-podium')
            }}
          >
            {renderCarouselPics()}
          </Carousel>
        </div>
      </div>
    </Stack>
  )
}

export default AllTimeWinners
