import { useMediaQuery, useTheme } from '@mui/material'

import { ColorModeTypeEnum } from '@enums'

import { IDialogProps } from './Dialog.types'
import { StyledDialog, StyledDrawer } from './Dialog.styled'

const Dialog = (props: IDialogProps) => {
  const {
    mode = ColorModeTypeEnum.LIGHT,
    isOpen = false,
    onClose,
    children
  } = props

  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  if (isXs) {
    return (
      <StyledDrawer mode={mode} anchor="bottom" open={isOpen} onClose={onClose}>
        {children}
      </StyledDrawer>
    )
  }

  return (
    <StyledDialog mode={mode} open={isOpen} onClose={onClose}>
      {children}
    </StyledDialog>
  )
}

export default Dialog
