import { type UserProfile } from '@elitecompetitions/core-api'

import { HotJarEventEnum } from '@enums'

const hotjarUserIdentificationEvent = ({
  isLoggedIn,
  profile
}: {
  isLoggedIn: boolean
  profile: UserProfile | null
}) => {
  if (isLoggedIn && profile) {
    try {
      const { email, userId } = profile

      if (typeof window !== 'undefined' && window.hj) {
        window.hj<
          HotJarEventEnum,
          {
            firebaseUid: string
          }
        >(HotJarEventEnum.IDENTIFY, email, {
          firebaseUid: userId
        })
      }
    } catch (err) {
      console.error('Error in hotjarUserIdentificationEvent', err)
    }
  }
}
export default hotjarUserIdentificationEvent
