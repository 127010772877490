import { groupBy, keyBy, max, uniq } from 'lodash-es'

import { observable, action } from 'mobx'

import { DateTime } from 'luxon'

import {
  child,
  get,
  getDatabase,
  onChildChanged,
  ref,
  set
} from 'firebase/database'

import {
  type ReservedTicketDTO,
  TicketsService,
  CompetitionsService,
  type CompetitionBaseDto
} from '@elitecompetitions/core-api'

import config from '@services/config'

import { getFirebaseApp } from '@utils/Firebase'

import { authStoreFactory } from './Auth'

const registerUsersReservedTicketsListener = async (
  userId: number,
  changeTicketHandler: () => void
) => {
  const UserReservedTicketsRef = child(
    ref(getDatabase(getFirebaseApp())),
    `UsersReservedTickets/${userId}`
  )
  const snapshot = await get(UserReservedTicketsRef)

  if (!snapshot.exists()) {
    await set(UserReservedTicketsRef, { id: userId })
  }

  changeTicketHandler()

  const ticketsChangedUnsubscribe = onChildChanged(
    UserReservedTicketsRef,
    changeTicketHandler
  )

  return () => {
    ticketsChangedUnsubscribe()
  }
}

export class CartStore {
  timerTickId: number | undefined
  cartClearTimerStepInMS = 1000
  lastSyncedAt = 0
  private unsubscribeReservedTicketsListener: (() => void) | undefined =
    undefined

  @observable cartTickets: ReservedTicketDTO[] = []
  @observable cartTicketsByCompetition: {
    competition: CompetitionBaseDto
    tickets: ReservedTicketDTO[]
  }[] = []
  @observable timeRemainingInSec = config.cart.cleanupIntervalSeconds

  async attachUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener =
      await registerUsersReservedTicketsListener(
        authStoreFactory().profile.id,
        () => {
          this.syncAll()
        }
      )
  }

  async removeUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener?.()
  }

  async syncAll() {
    const cartTickets = await TicketsService.getUserReservedTickets()

    let ticketsCompetitions: CompetitionBaseDto[] = []

    if (cartTickets.length !== 0) {
      const { data = [] } = await CompetitionsService.getCompetitions({
        filterIdIn: uniq(
          cartTickets.map(cartTicket => cartTicket.competitionId)
        ),
        take: -1
      })

      ticketsCompetitions = data
    }

    const competitionsMap = keyBy(ticketsCompetitions, ticketsCompetition => {
      return ticketsCompetition.id
    })
    const groupedTickets = groupBy(cartTickets, cartTicket => {
      return cartTicket.competitionId
    })

    const cartTicketsByCompetition = Object.entries(groupedTickets).map(
      ([competitionId, tickets]) => {
        const competition = competitionsMap[competitionId]

        return {
          competition,
          tickets
        }
      }
    )

    this.setCartTicketsByCompetition(cartTicketsByCompetition)
    this.setCartTickets(cartTickets)

    this.syncCartTimer(cartTickets)

    if (this.timerTickId) {
      clearInterval(this.timerTickId)
    }

    this.timerTickId = window.setInterval(() => {
      this.timerCountdown()
    }, this.cartClearTimerStepInMS)
  }

  syncCartTimer(cartTickets: ReservedTicketDTO[]) {
    if (cartTickets && cartTickets.length > 0) {
      const updatedAtTimeStamps = cartTickets.map(
        ({ reservedAt }) => reservedAt
      )
      const lastUpdatedTimeStamp = max(updatedAtTimeStamps)
      const now = DateTime.now()
      const passedTimeStampInSec = now
        .diff(DateTime.fromISO(lastUpdatedTimeStamp))
        .as('seconds')

      const remainingTimeStampInSec =
        config.cart.cleanupIntervalSeconds - passedTimeStampInSec

      this.setTimer(remainingTimeStampInSec)
      this.timerCountdown()
    }
  }

  @action
  timerCountdown() {
    if (this.timeRemainingInSec > 0) {
      this.setTimer(
        this.timeRemainingInSec - this.cartClearTimerStepInMS / 1000
      )
    }
  }

  @action
  setTimer(timeInSec) {
    this.timeRemainingInSec = timeInSec < 0 ? 0 : timeInSec
  }

  @action
  resetTimer() {
    this.timeRemainingInSec = config.cart.cleanupIntervalSeconds
  }

  @action
  setCartTickets(tickets) {
    this.lastSyncedAt = Date.now()
    this.cartTickets = tickets
  }

  @action
  setCartTicketsByCompetition(ticketsByCompetition) {
    this.cartTicketsByCompetition = ticketsByCompetition
  }

  @action
  async removeFromCart(ticket: ReservedTicketDTO) {
    await TicketsService.clearCart({
      ticketId: ticket.ticketId
    })
  }

  @action
  successClearCart() {
    this.setCartTickets([])
  }
}

let cartStore: CartStore

export function cartStoreFactory() {
  if (!process.browser) {
    cartStore = new CartStore()
  }
  if (process.browser && !cartStore) {
    cartStore = new CartStore()
  }

  return cartStore
}

export default cartStore
