import { useCallback } from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Carousel } from 'react-responsive-carousel'

import { Stack } from '@mui/material'

import { Title } from '@components/ui/atoms'

import { getLocalAssetFromCDN, preprocessImageUrl } from '@helpers'

import { ICategoryWinnersProps } from './CategoryWinners.types'

const CategoryWinners = (props: ICategoryWinnersProps) => {
  const { categoryWinners = [], categoryName } = props

  const router = useRouter()

  const image = (path, size, format) => {
    if (path) {
      const { srcset } = preprocessImageUrl(path, size, format)

      return srcset
    } else {
      return false
    }
  }

  const renderCarouselPics = useCallback(() => {
    return categoryWinners.map((value, index) => {
      const {
        winnerName,
        prizeName,
        competitionName,
        drawTime,
        winnerProfileImage
      } = value

      return (
        <Link
          key={`category-winner-${index}`}
          href="/winners-podium"
          prefetch={false}
        >
          <a className="ptr">
            <Image
              alt={winnerName ? winnerName : `Winner Image ${index}`}
              src={
                winnerProfileImage
                  ? image(winnerProfileImage, 'medium', 'webp')
                  : getLocalAssetFromCDN(
                      '/assets/img/competition-default-image.webp'
                    )
              }
              loading="lazy"
              width={600}
              height={350}
            />

            {prizeName && (
              <h4 className="px-4 mt-4 mb-2">
                <span>{prizeName}</span>
              </h4>
            )}

            {competitionName && (
              <h4 className="px-4 mt-4 mb-2">
                <span>{competitionName}</span>
              </h4>
            )}

            {winnerName && <p>{winnerName}</p>}

            {drawTime && <p>{drawTime}</p>}
          </a>
        </Link>
      )
    })
  }, [categoryWinners])

  if (categoryWinners.length === 0) {
    return null
  }

  return (
    <Stack direction="column" gap="16px" width="100%" component="section">
      <Title variant="h2">
        {`Previous ${categoryName} Competition Winners`}
      </Title>

      <div className="row justify-content-md-center all-time-winners">
        <div className="col col-md-7 ptr">
          <Carousel
            autoPlay={true}
            showArrows={true}
            emulateTouch={true}
            infiniteLoop={true}
            interval={5000}
            showThumbs={false}
            stopOnHover={true}
            showIndicators={true}
            showStatus={false}
            onClickItem={async () => {
              await router.push('/winners-podium')
            }}
          >
            {renderCarouselPics()}
          </Carousel>
        </div>
      </div>
    </Stack>
  )
}

export default CategoryWinners
