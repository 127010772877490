import { ColorModeTypeEnum } from '@enums'

import { StyledSkeleton } from './Skeleton.styled'
import { ISkeletonProps } from './Skeleton.types'

const Skeleton = (props: ISkeletonProps) => {
  const {
    borderRadius = 8,
    mode = ColorModeTypeEnum.DARK,
    ...otherProps
  } = props

  return (
    <StyledSkeleton mode={mode} borderRadius={borderRadius} {...otherProps} />
  )
}

export default Skeleton
